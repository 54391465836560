<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-if="searchStatus" status="my-orange" @click="searchStatus = false">隐藏查询</vxe-button>
        <vxe-button v-if="!searchStatus" status="my-orange" icon="fa fa-search" @click="searchStatus = true">查询</vxe-button>
      </template>
    </vxe-toolbar>
    <div v-if="searchStatus" class="search">
      <vxe-form title-align="right" title-width="120" title-colon :data="listQuery" @submit="handleSearch(true)" @reset="handleSearch(true, true)">
        <vxe-form-item title="分类" span="6">
          <vxe-select v-model="listQuery.param.category" placeholder="请选择分类" clearable>
            <vxe-option
              v-for="(item, index) in dict['volunteerTaskType']"
              :key="index"
              :label="item"
              :value="index"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item title="标题" span="6">
          <vxe-input v-model="listQuery.param.title" placeholder="请输入标题" clearable />
        </vxe-form-item>
        <vxe-form-item title="报名时间" span="12">
          <el-date-picker
            v-model="listQuery.param.claimantsTime"
            size="small"
            clearable
            type="datetimerange"
            align="center"
            value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="报名开始时间"
            end-placeholder="报名结束时间"
            style="minWidth: 100%;"
          />
        </vxe-form-item>
        <vxe-form-item title="任务时间" span="12">
          <el-date-picker
            v-model="listQuery.param.time"
            size="small"
            clearable
            type="datetimerange"
            align="center"
            value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="任务开始时间"
            end-placeholder="任务结束时间"
            style="minWidth: 100%;"
          />
        </vxe-form-item>
        <vxe-form-item title="状态" span="6">
          <vxe-select v-model="listQuery.param.status" placeholder="请选择状态" clearable>
            <vxe-option
              v-for="(item, index) in statusArray"
              :key="index"
              :label="item"
              :value="index"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <CustomList ref="CustomList" :columns="tableColumns" :list-query="listQuery" @getList="getList">
      <CustomPic slot="checkoutCodeImage" slot-scope="{row}" :src="row.checkoutCodeImage || ''" :src-list="[row.checkoutCodeImage]" />
      <CustomPic slot="photos" slot-scope="{row}" :src="getPhotos(row.photos, 0)" :src-list="getPhotos(row.photos)" />
      <template slot="operating" slot-scope="{row}">
        <el-button size="small" type="text" @click="handleDetail(row.id)">任务信息</el-button>
      </template>
    </CustomList>
    <Form ref="Form" />
  </div>
</template>

<script>
import CustomList from '@/components/CustomList/index'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import { mapActions } from 'vuex'
import Form from './form'
import QRCode from 'qrcode'
export default {
  name: 'ReleaseRecord',
  components: { Form, CustomList, ...CustomTemplate },
  data() {
    return {
      searchStatus: false,
      dict: {
        volunteerTaskType: {}
      },
      statusArray: { '0': '下线', '2': '发布' },
      listQuery: {
        param: {
          belongs: this.$route.params.belongs,
          initiatorUserId: this.$route.params.initiatorUserId
        }
      },
      tableColumns: [
        {
          prop: 'categoryName',
          title: '分类',
          minWidth: 100
        },
        {
          title: '签到码',
          slot: 'checkoutCodeImage'
        },
        {
          title: '任务信息',
          formatter: (row, column) => {
            let text = row.title || ''
            text += row.title && row.intro ? ' <br/> ' : ''
            text += row.intro || ''
            return text
          },
          minWidth: 200
        },
        {
          title: '图片',
          slot: 'photos'
        },
        {
          prop: 'address',
          title: '地点',
          minWidth: 150
        },
        {
          title: '联系人',
          formatter: (row, column) => {
            let text = row.contactPerson || ''
            text += row.contactPerson && row.contactPhone ? ' <br/> ' : ''
            text += row.contactPhone || ''
            return text
          },
          minWidth: 120
        },
        {
          title: '签到类型',
          formatter: (row, column) => {
            switch (row.checkInType) {
              case 0:
                return '活动前'
              case 1:
                return '活动前后'
              default:
                return ''
            }
          },
          minWidth: 100
        },
        {
          prop: 'credits',
          title: '奖励积分',
          minWidth: 100
        },
        {
          title: '报名人数（已报名/总人数）',
          formatter: (row, column) => {
            return `${row.claimedNumner || 0}/${row.claimantsQuantity || 0}`
          },
          minWidth: 200
        },
        {
          title: '状态',
          formatter: (row, column) => {
            return row.status in this.statusArray ? this.statusArray[row.status] : ''
          },
          minWidth: 100
        },
        {
          title: '报名时间',
          formatter: (row, column) => {
            return `${row.claimantsStartTime} <br/> ～ <br/> ${row.claimantsEndTime}`
          },
          minWidth: 160
        },
        {
          title: '任务时间',
          formatter: (row, column) => {
            return `${row.startTime} <br/> ～ <br/> ${row.endTime}`
          },
          minWidth: 160
        },
        {
          title: '操作',
          fixed: 'right',
          slot: 'operating',
          minWidth: 100
        }
      ]
    }
  },
  created() {
    this.getSysDictList('volunteerTaskType')
  },
  methods: {
    ...mapActions(['getSysDictServe', 'pubTaskPublicPageList', 'pubTaskUpdate']),
    getSysDictList(typeCode) {
      this.getSysDictServe({ typeCode }).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }
        res.data.forEach(res => {
          this.dict[typeCode][res.dictPidVal] = res.dictDesc
        })
      })
    },
    handleSearch(resetCurrentPage = false, resetSearch = false) {
      if (resetSearch) {
        Object.assign(this.listQuery, this.$options.data.call(this).listQuery)
      }
      this.customListGetList(resetCurrentPage)
    },
    customListGetList(resetCurrentPage = false) {
      this.$refs.CustomList.getList(resetCurrentPage)
    },
    getList(params, callback) {
      if ('param' in params && 'claimantsTime' in params['param'] && params['param']['claimantsTime']) {
        params['param']['claimantsStartTime'] = params['param']['claimantsTime'][0]
        params['param']['claimantsEndTime'] = params['param']['claimantsTime'][1]
      }
      if ('param' in params && 'time' in params['param'] && params['param']['time']) {
        params['param']['startTime'] = params['param']['time'][0]
        params['param']['endTime'] = params['param']['time'][1]
      }
      delete params['param']['claimantsTime']
      delete params['param']['time']

      this.pubTaskPublicPageList(params).then(response => {
        if (response.code === 200) {
          this.getQRCode(response).then(response => {
            callback(response)
          }).catch(() => {
            callback(response)
          })
        } else {
          callback(response)
        }
      }).catch(() => {})
    },
    handleDetail(id) {
      this.$refs.Form.handleDetail(id)
    },
    getPhotos(photos, index) {
      let photosArray = []
      if (photos) {
        photosArray = photos.split(',')
      }

      if (index === 0) {
        return photosArray.length ? photosArray[0] : ''
      } else {
        return photosArray
      }
    },
    getQRCode(response) {
      return new Promise(resolve => {
        response.data.records.forEach(item => {
          QRCode.toDataURL(item.checkoutCode || '', { width: 500 }).then(url => {
            item['checkoutCodeImage'] = url
          }).catch(e => {
            item['checkoutCodeImage'] = ''
          })
        })
        resolve(response)
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper{
    overflow: auto;
  }
</style>
